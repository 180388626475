import React from "react";
import MainHome from "../../components/MainHome/index.js";
import SEO from "../../components/layout/seo.js";

export default function MainHomePage() {
  return (
    <>
      <SEO
        title="Trusted Experts. Answers they agree on."
        description="We are ending the spread of misinformation one healthcare specialty at a time."
        keywords={[`medical answers`, `medical questions`, `medanswers`]}
      />
      <MainHome />
    </>
  );
}
