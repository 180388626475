import React from "react";
export default function Intellectual() {
  return (
    <div className="intellectual">
      <div className="content">
        <h3 className="int_heading">Intellectual Property</h3>
        <p className="int_par">
          We have an aggressive IP strategy that includes a patent on
          non-invasive, comprehensive, hormone testing and provisional filings
          on our technology and genetic integrations, as well as trademarks as
          applicable.
        </p>
      </div>
    </div>
  );
}
