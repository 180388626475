import React from "react";
import { useStaticQuery, graphql } from "gatsby";

export default function Cosmicdata() {
  const data = useStaticQuery(graphql`
    query test {
      allCosmicjsMedanswers {
        edges {
          node {
            metadata {
              heading
              subheading
              img_desktop {
                url
              }
              support_img {
                url
              }
              img_mobile {
                url
              }
            }
          }
        }
      }
      allCosmicjsLeadershipTeams {
        edges {
          node {
            slug
            metadata {
              member_img {
                url
              }
              designation
              name
            }
          }
        }
      }
      allCosmicjsSpecialitiesMedanswers {
        nodes {
          metadata {
            background_color
            description
            heading
          }
        }
      }
      allCosmicjsMedanswerCaseStudies {
        nodes {
          metadata {
            description
            heading
            icons {
              url
            }
          }
        }
      }
      allCosmicjsInvestorTeams {
        edges {
          node {
            slug
            metadata {
              designation
              investor_img {
                url
              }
              name
            }
          }
        }
      }
      allCosmicjsIntelectualProperties {
        edges {
          node {
            slug
            metadata {
              description
              title
            }
          }
        }
      }
      allCosmicjsReproductiveCouncils {
        edges {
          node {
            slug
            metadata {
              designation
              member_team {
                url
              }
              name
            }
          }
        }
      }
    }
  `);
  return data;
}
